export default [
  {
    text: "Naše kovárna se nachází v objektu barokního statku z roku 1871. V tomto objektu se dříve nacházela zemědělská kovárna a kování koní. Po letech jsme do objektu řemeslo vrátili."
  }, 
  {
    text: "Naším cílem je originalita kovaných výrobků a perfektní řemeslné zpracování. Z valné většiny se jedná o zakázkovou výrobu na míru od návrhu, zaměření až po montáž. Zpravidla se jedná o ruřní výrobu."
  },
  {
    text: "Díky rokům zkušeností a stovkám zhotovených projektů se vždy můžete spolehnout na maximální kvalitu, osobní přístup, rychlost dokončení i prvotřídní jakost použitých materiálů."
  }
]
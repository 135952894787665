import React, { useRef, useEffect, useContext } from "react"
import styles from "./Stat.module.scss"
import { BsFillPeopleFill } from "react-icons/bs"
import { AiOutlineFileProtect } from "react-icons/ai"
import { MdDateRange } from "react-icons/md"
import { useCountUp } from "react-countup"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/GlobalContextProvider"
import PropTypes from 'prop-types';

const Stat = ({ stat }) => {
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)

  let options = {
    root: null,
    rootMargin: "0px 0px",
    threshold: 0.5,
  }

  const statRef = useRef(null)

  const setShown = () => {
    dispatch({ type: "TOGGLE_SHOWN" })
  }

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setTimeout(() => {
          setShown()
        }, 3000)
        start()
        observer.unobserve(entries[0].target)
      }
    }, options)
    if (statRef.current) {
      observer.observe(statRef.current)
    }
    return () => {
      if (statRef.current) {
        observer.unobserve(statRef.current)
      }
    }
  }, [])

  const { countUp, start } = useCountUp({
    duration: 3,
    end: stat.node.number,
    startOnMount: false,
  })

  return (
    <div ref={statRef} className={styles.stat}>
      <div className={styles.icon}>
        {stat.node.icon === "zakaznik" && <BsFillPeopleFill />}
        {stat.node.icon === "firma" && <AiOutlineFileProtect />}
        {stat.node.icon === "rok" && <MdDateRange />}
      </div>
      <div className={styles.number}>
        {!state.shown ? countUp : stat.node.number}
        <span className={styles.plus}>+</span>
      </div>
      <div className={styles.text}>
        <div className={styles.light}>{stat.node.text}</div>
        <div className={styles.bold}>{stat.node.mainText}</div>
      </div>
    </div>
  )
}

export default Stat

Stat.propTypes = {
  stat: PropTypes.object,
}
import React from "react"
import styles from "./KovoartAbout.module.scss"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import Stat from "../stats/Stat"
import Fade from "react-reveal/Fade"
import PropTypes from 'prop-types';
import about from "../../constants/about"

const KovoartAbout = ({ place }) => {
  const data = useStaticQuery(graphql`
    query {
      image: contentfulAsset(title: { eq: "CANR8015" }) {
        fluid(maxWidth: 1600, quality: 50) {
          ...GatsbyContentfulFluid
        }
      }
      imageTop: contentfulAsset(title: { eq: "CANR8180" }) {
        fluid(maxWidth: 1000, quality: 50) {
          ...GatsbyContentfulFluid
        }
      }
      allContentfulStatistiky(sort: { fields: createdAt, order: ASC }) {
        edges {
          node {
            number
            id
            text
            mainText
            icon
          }
        }
      }
    }
  `)

  return (
    <section
      className={`${styles.section} ${
        place === "underCta" ? styles.sectionUnderCta : ""
      }`}
    >
      <div className={`${styles.about}`}>
        <div className={styles.underlay} />
        <div className={styles.underlayLeft} />
        <div className={styles.text}>
          <Fade bottom duration={700}>
            <h2>
              Kovárna <span className="heading-bold">Kovoart</span>
            </h2>
          </Fade>
          {about.map((p, i) => {
            return (
              <Fade key={i} bottom duration={700}>
                <p>
                  {p.text}
                </p>
              </Fade>
            )
          })}
          <div className={styles.stats}>
            {data.allContentfulStatistiky.edges.map((stat,i) => (
              <Stat key={i} stat={stat} />
            ))}
          </div>
        </div>
        <BackgroundImage className={styles.image} fluid={data.image.fluid} />
        <BackgroundImage
          className={styles.imagesec}
          fluid={data.imageTop.fluid}
        />
      </div>
    </section>
  )
}

export default KovoartAbout

KovoartAbout.propTypes = {
  place: PropTypes.string,
}